/*From Live-Page - compiled to .scss on 5th July 2017*/

//fonts and colors
@import "Variables/fonts";
@import "Variables/colors";

//@extend-elements
//original selectors
//section.content aside.left-content h1, section.content aside.left-content h2, section.content aside.left-content h3
%extend_1 {
  position: relative;
  font-family: $font_1;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: $color_pistachio_approx;
  text-transform: uppercase;
  height: 20px;
  padding: 13px 0 12px;
  margin: 0 0 6px 0;
}

//original selectors
//section.content aside.left-content h1:after, section.content aside.left-content h2:after, section.content aside.left-content h3:after
%extend_2 {
  content: attr(data-title);
  position: absolute;
  left: 0;
  top: 13px;
  display: block;
  width: 100%;
  height: 5px;
  overflow: hidden;
  color: $color_olivetone_approx;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: .3;
}

//original selectors
//section.content div.main-content h1, section.content div.main-content h2, section.content div.main-content h3
%extend_3 {
  position: relative;
  font-family: $font_1;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  color: $color_peat_approx;
  text-transform: uppercase;
  padding: 0 0 12px 0;
  margin: 0 0 6px 0;
}

//original selectors
//section.content div.main-content h1:after, section.content div.main-content h2:after, section.content div.main-content h3:after
%extend_4 {
  content: attr(data-title);
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 5px;
  overflow: hidden;
  color: $color_judge_gray_approx;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: .3;
}

//original selectors
//section.content div.main-content div.csc-default-introduction h1, section.content div.main-content div.csc-default-introduction h2, section.content div.main-content div.csc-default-introduction h3
%extend_5 {
  color: $color_peat_approx;
  font-size: 26px;
  line-height: 26px;
}

//original selectors
//section.content div.main-content div.csc-default-introduction h1:after, section.content div.main-content div.csc-default-introduction h2:after, section.content div.main-content div.csc-default-introduction h3:after
%extend_6 {
  color: $color_judge_gray_approx;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  opacity: .1;
  height: 6px;
}

//original selectors
//section.content div.main-content table.contenttable th, section.content div.main-content table.contenttable td
%extend_7 {
  padding: 8px 12px;
  border-right: 1px solid $color_olivetone_approx;
  position: relative;
}

//original selectors
//.download, .button, .internal-link
%extend_8 {
  display: inline-block;
  padding: 6px 10px 5px;
  margin: 9px 0 0;
  background-color: $color_pistachio_approx;
  box-shadow: 0 1px 5px $black_50,inset 0 1px 2px $white_30,inset 0 0 2px 2px $color_shalimar_30_approx;
  border: 1px solid $color_limeade_approx;
  border-radius: 8px;
  background-image: linear-gradient(to top, $color_limeade_approx,$color_rio_grande_approx);
  background-size: 100%;
  font-family: $font_1;
  font-size: 16px;
  color: $color_verdun_green_approx;
  cursor: pointer;
  text-decoration: none !important;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 1px 1px $white_20;
}

//original selectors
//.download:active, .button:active, .internal-link:active
%extend_9 {
  top: 1px;
  left: 1px;
  position: relative;
  background: $color_pistachio_approx;
  box-shadow: inset 0 1px 2px $white_30,inset 0 0 2px 2px $color_shalimar_30_approx;
}

//original selectors
//form input[type=text], form textarea
%extend_10 {
  padding: 4px 10px;
  border-radius: 8px / 8px 8px 7px 7px;
  background-color: $white;
  box-shadow: 0 1px 1px $white_70,inset 1px 2px 4px $black_20;
  border: solid 1px $color_stonewall_30_approx;
}

//original selectors
//form div.radio, form div.radio span, form div.radio input
%extend_11 {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

//original selectors
//div.contact label.firstname-field, div.contact label.lastname-field, div.contact label.street-field, div.contact label.zipcode-field, div.contact label.city-field, div.contact label.company-field, div.contact label.country-field, div.contact label.section-field, div.contact label.phone-field, div.contact label.fax-field, div.contact label.mobile-field, div.contact label.email-field
%extend_12 {
  float: left;
  width: 232px;
  margin-right: 20px;
}

//original selectors
//section.header-slider div.previous, section.header-slider div.next
%extend_13 {
  position: absolute;
  top: 180px;
  width: 43px;
  height: 43px;
  background: no-repeat;
  z-index: 5;
  cursor: pointer;
}

div {
  &.inner {
    width: 100%;
    max-width: 990px;
  }
  &.contact {
    label {
      &.salutation-field-male {
        float: left;
        margin-right: 19px;
        cursor: pointer;
      }
      &.salutation-field-female {
        float: left;
        cursor: pointer;
      }
      &.firstname-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.lastname-field {
        @extend %extend_12;
        margin-right: 0;
        input {
          width: 210px;
        }
      }
      &.street-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.zipcode-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.city-field {
        @extend %extend_12;
        margin-right: 0;
        input {
          width: 210px;
        }
      }
      &.company-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.country-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.section-field {
        @extend %extend_12;
        margin-right: 0;
        input {
          width: 210px;
        }
      }
      &.phone-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.fax-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.mobile-field {
        @extend %extend_12;
        margin-right: 0;
        input {
          width: 210px;
        }
      }
      &.email-field {
        @extend %extend_12;
        input {
          width: 210px;
        }
      }
      &.message-field {
        width: 740px;
        textarea {
          width: 718px;
          height: 200px;
          resize: none;
        }
      }
    }
    fieldset.salutation-field {
      float: left;
      width: 230px;
      margin-right: 20px;
      span {
        float: left;
        margin-right: 19px;
      }
    }
  }
  &.header-content div {
    &.empty-header {
      height: 50px;
    }
    &.csc-default div.csc-textpic {
      width: 92%;
      max-width: 1124px;
      margin: 0 auto;
      position: relative;
      overflow: visible;
      z-index: 2;
      margin-top: -40px;
      img {
        background: $white;
        border: solid 3px $white;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 0 1px 10px $color_black_30_approx;
      }
      div.csc-textpic-imagewrap {
        overflow: visible;
      }
    }
  }
  &.newsletter label {
    &.type-field-subscribe {
      float: left;
      width: 103px;
      margin-right: 9px;
      cursor: pointer;
    }
    &.type-field-unsubscribe {
      float: left;
      width: 102px;
      cursor: pointer;
    }
    &.firstname-field {
      float: left;
      margin-right: 9px;
      input[type=text] {
        width: 79px;
      }
    }
    &.lastname-field {
      float: left;
      input[type=text] {
        width: 93px;
      }
    }
    &.email-field {
      float: left;
      input[type=text] {
        width: 203px;
      }
    }
  }
}
nav {
  &.mobile-navigation {
    display: none;
  }
  &.breadcrumb-navigation {
    clear: both;
    top: -12px;
    margin-bottom: -45px;
    position: relative;
    z-index: 5;
    z-index: 4;
    ul li {
      float: left;
      a {
        font-size: 11px;
        color: $color_peat_approx;
        text-decoration: underline;
        margin: 0 5px;
        &:hover {
          text-decoration: none;
        }
      }
      &.first a {
        margin-left: 0;
      }
      &.last a {
        text-decoration: none;
      }
    }
  }
}
section {
  &.content {
    margin: 25px 0 0;
    div {
      &.main-content {
        float: right;
        width: 740px;
        margin: 13px 0 0;
        min-height: 400px;
        div {
          &.empty-main-content {
            height: 200px;
          }
          &.csc-default {
            clear: both;
          }
          &.csc-default-introduction {
            h1 {
              @extend %extend_5;
              &:after {
                @extend %extend_6;
              }
            }
            h2 {
              @extend %extend_5;
              &:after {
                @extend %extend_6;
              }
            }
            h3 {
              @extend %extend_5;
              &:after {
                @extend %extend_6;
              }
            }
            p {
              font-family: $font_0;
              font-size: 22px;
              line-height: 30px;
              color: $color_donkey_brown_approx;
            }
          }
          &.csc-half-size {
            clear: none;
            width: 360px;
            margin-right: 20px;
            float: left;
            div.csc-textpic-border div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
          }
          &.csc-half-size-last {
            margin-right: 0;
          }
          &.csc-textpic-border {
            div.csc-textpic-image span.image {
              position: relative;
              display: block;
              &:after {
                display: block;
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                margin-left: -335px;
                width: 670px;
                height: 10px;
                background: url(../Image/Desktop/Content/Shadow.png) no-repeat;
              }
            }
            &.csc-textpic-intext-right div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-left div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-right-nowrap div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
            &.csc-textpic-intext-left-nowrap div.csc-textpic-image span.image:after {
              bottom: -7px;
              //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
              transform: scale(.5, .5);
            }
          }
          &.csc-textpic-intext-left-nowrap div.csc-textpic-text {
            margin-left: 380px;
          }
          &.csc-textpic-intext-right-nowrap div.csc-textpic-text {
            margin-right: 380px;
          }
          &.csc-textpic-imagecolumn span.image:after {
            display: none !important;
          }
        }
        p {
          margin-bottom: 9px;
          a {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
        ul {
          li {
            padding: 0 0 0 15px;
            background: url(../Image/Desktop/Icon/ListLevel1.png) no-repeat 0 6px;
            margin: 0 0 9px 0;
            ul {
              margin: 9px 0 0;
              li {
                background-image: url(../Image/Desktop/Icon/ListLevel2.png);
              }
            }
          }
          &.csc-menu li.active a {
            font-weight: bold;
          }
          &.special li {
            padding: 8px 0 8px 35px;
            margin: 0 0 2px 0;
            background: url(../Image/Desktop/Icon/ListSpecial.png) no-repeat 0 1px;
          }
          &.links li {
            background: url(../Image/Desktop/Icon/ListLinks.png) no-repeat 0 3px;
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        h1 {
          @extend %extend_3;
          &:after {
            @extend %extend_4;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h2 {
          @extend %extend_3;
          &:after {
            @extend %extend_4;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h3 {
          @extend %extend_3;
          &:after {
            @extend %extend_4;
          }
          a {
            color: $color_peat_approx;
          }
        }
        h4 {
          font-weight: bold;
          margin-bottom: 9px;
        }
        h5 {
          font-weight: bold;
          margin-bottom: 9px;
        }
        caption.csc-textpic-caption {
          background: $color_soft_peach_approx;
          margin: 0 1px 0 0;
          padding: 3px 9px 8px;
        }
        table.contenttable {
          width: 100%;
          border-collapse: collapse;
          th {
            font-size: 14px;
            font-weight: bold;
            color: $color_olivetone_approx;
            @extend %extend_7;
            &:last-child {
              border-right: none;
            }
          }
          td {
            @extend %extend_7;
            &:last-child {
              border-right: none;
            }
          }
          tr {
            &:nth-child(even) {
              background: $white;
            }
            &:nth-child(odd) {
              background: $color_kidnapper_approx;
            }
          }
          thead tr {
            background: $white !important;
          }
        }
      }
      &.csc-default {
        margin: 0 0 16px 0;
      }
      &.divider {
        margin-bottom: 25px;
        border-top: 1px solid $color_dawn_pink_approx;
        height: 1px;
        hr {
          display: none;
        }
      }
    }
    nav.sub-navigation {
      margin: 40px 0 80px;
      width: 230px;
      ul li {
        a {
          display: block;
          font-family: $font_1;
          font-size: 16px;
          color: $color_donkey_brown_approx;
          border-bottom: 1px solid $color_bon_jour_approx;
          padding: 13px 5px 13px 24px;
          background: url(../Image/Desktop/Icon/SubNavigation.png) 0 13px no-repeat;
          &:hover {
            color: $color_citron_approx;
            background-image: url(../Image/Desktop/Icon/SubNavigationActive.png);
          }
        }
        &.active a {
          color: $color_citron_approx;
          background-image: url(../Image/Desktop/Icon/SubNavigationActive.png);
        }
      }
    }
    aside.left-content {
      width: 230px;
      margin: 6px 20px 0 0;
      p {
        margin-bottom: 9px;
      }
      h1 {
        @extend %extend_1;
        &:after {
          @extend %extend_2;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      h2 {
        @extend %extend_1;
        &:after {
          @extend %extend_2;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      h3 {
        @extend %extend_1;
        &:after {
          @extend %extend_2;
        }
        &.header-icon-contact {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Contact.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-brochure {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Brochure.png);
          &:after {
            left: 50px;
          }
        }
        &.header-icon-newsletter {
          padding-left: 50px;
          background-repeat: no-repeat;
          background-image: url(../Image/Desktop/Icon/Newsletter.png);
          &:after {
            left: 50px;
          }
        }
      }
      DIV.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
        margin-bottom: 0;
      }
    }
    p.green-bold, p.detail, span.detail {
      font-size: 14px;
      font-weight: bold;
      color: $color_pistachio_approx;
      padding-bottom: 3px;
    }
  }
  &.header-slider {
    width: 92%;
    max-width: 1124px;
    height: 412px;
    margin: 0 auto;
    position: relative;
    div {
      &.mask {
        overflow: hidden;
        background-color: $white;
        border: solid 3px $white;
        margin: -40px auto 0;
        z-index: 2;
        position: relative;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 0 1px 10px $color_black_30_approx;
        height: 412px;
        ul.container {
          position: absolute;
          left: 0;
          top: 0;
          height: 412px;
          li {
            float: left;
          }
        }
      }
      &.previous {
        @extend %extend_13;
        left: -23px;
        background-image: url(../Image/Desktop/Plugin/HeaderSlider/Previous.png);
      }
      &.next {
        @extend %extend_13;
        right: -23px;
        background-image: url(../Image/Desktop/Plugin/HeaderSlider/Next.png);
      }
    }
    ul.navigation {
      position: absolute;
      right: 65px;
      bottom: -21px;
      z-index: 5;
      li {
        margin-right: 2px;
        float: left;
        width: 28px;
        height: 30px;
        background: url(../Image/Desktop/Plugin/HeaderSlider/Position.png) no-repeat;
        cursor: pointer;
        &.active {
          background-image: url(../Image/Desktop/Plugin/HeaderSlider/PositionActive.png);
        }
      }
    }
  }
  &#google-map {
    form {
      label {
        float: left;
        margin-right: 20px;
        &.street-field input[type=text] {
          width: 200px;
        }
        &.zipcode-field input[type=text] {
          width: 80px;
        }
        &.city-field input[type=text] {
          width: 190px;
        }
      }
      div.submit {
        float: left;
        position: relative;
        top: -11px;
      }
    }
    div#google-map-canvas {
      margin: 20px 0 0;
      height: 400px;
    }
  }
}
.download {
  @extend %extend_8;
  &:hover {
    background: $color_pistachio_approx;
  }
  &:active {
    @extend %extend_9;
  }
}
.button {
  @extend %extend_8;
  &:hover {
    background: $color_pistachio_approx;
  }
  &:active {
    @extend %extend_9;
  }
}
.internal-link {
  @extend %extend_8;
  &:hover {
    background: $color_pistachio_approx;
  }
  &:active {
    @extend %extend_9;
  }
}
form {
  label {
    display: block;
    position: relative;
    margin-bottom: 9px;
  }
  input[type=text] {
    @extend %extend_10;
  }
  textarea {
    @extend %extend_10;
  }
  span.label {
    position: absolute;
    left: 10px;
    top: 5px;
    z-index: 1;
    color: $color_nobel_approx;
    font-style: italic;
    cursor: text;
  }
  div.radio {
    @extend %extend_11;
    display: inline;
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
    span {
      @extend %extend_11;
      display: inline;
      display: inline-block;
      zoom: 1;
      text-align: center;
      background: url(../Image/Desktop/Icon/Radio.png) 0 0 no-repeat;
      &.checked {
        background: url(../Image/Desktop/Icon/RadioChecked.png) 0 0 no-repeat;
      }
    }
    input {
      @extend %extend_11;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      border: none;
      background: none;
      display: inline;
      display: inline-block;
      zoom: 1;
      text-align: center;
    }
  }
}
header.header {
  height: 198px;
  &:before {
    height: 200px;
  }
  nav {
    &.main-navigation {
      position: absolute;
      left: 232px;
      bottom: 30px;
      ul {
        li {
          float: left;
          a {
            font-size: 20px;
            font-family: $font_0;
            padding: 30px 12px 27px;
            display: block;
            color: $color_peat_approx;
            background: url(../Image/Desktop/Header/Spacer.png) no-repeat right bottom;
            z-index: 3;
            position: relative;
            letter-spacing: 1px;
            //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
            text-shadow: 0 1px 2px $white;
            &:hover {
              color: $color_limeade_approx;
            }
          }
          &.active {
            background: url(../Image/Desktop/Header/NavigationActive.png) no-repeat center -10px;
            a {
              color: $color_limeade_approx;
            }
          }
          &:hover {
            background: url(../Image/Desktop/Header/NavigationActive.png) no-repeat center -10px;
          }
          &.last a {
            background: none;
          }
        }
        &.level-2 {
          display: none;
        }
      }
      span.label {
        display: none;
      }
    }
    &.top-navigation {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 3;
      ul li {
        float: left;
        a {
          display: block;
          width: 26px;
          height: 26px;
          margin-left: 10px;
          background: no-repeat;
          &.home {
            margin-right: 10px;
            background-image: url(../Image/Desktop/Icon/Home.png);
            &:hover {
              background-image: url(../Image/Desktop/Icon/HomeHover.png);
            }
          }
          &.german {
            background-image: url(../Image/Desktop/Icon/German.png);
            &:hover {
              background-image: url(../Image/Desktop/Icon/GermanActive.png);
            }
            &.active {
              background-image: url(../Image/Desktop/Icon/GermanActive.png);
            }
          }
          &.english {
            background-image: url(../Image/Desktop/Icon/English.png);
            &:hover {
              background-image: url(../Image/Desktop/Icon/EnglishActive.png);
            }
            &.active {
              background-image: url(../Image/Desktop/Icon/EnglishActive.png);
            }
          }
          &.french {
            background-image: url(../Image/Desktop/Icon/French.png);
            &:hover {
              background-image: url(../Image/Desktop/Icon/FrenchActive.png);
            }
            &.active {
              background-image: url(../Image/Desktop/Icon/FrenchActive.png);
            }
          }
        }
      }
    }
  }
  div.branding {
    width: 232px;
    height: 185px;
    z-index: 5;
    left: 0;
    top: 10px;
    position: absolute;
    background: url(../Image/Desktop/Header/Branding.png) no-repeat 0 2px;
    text-align: center;
    a.logo {
      width: 189px;
      height: 123px;
      display: inline-block;
      margin-bottom: 17px;
      background: url(../Image/Desktop/Header/Logo.png);
    }
    div.reserve {
      position: relative;
      z-index: 5;
      text-align: left;
      h6 {
        cursor: pointer;
        font-weight: bold;
        color: $color_pistachio_approx;
        text-align: center;
        padding: 0;
      }
      div {
        &.reserve-content {
          overflow: hidden;
          height: 0;
          background: $white;
          border-right: 1px solid $color_gray_nurse_approx;
          border-left: 1px solid $color_gray_nurse_approx;
          fieldset {
            border-top: 1px solid $color_dawn_pink_approx;
            margin-top: 15px;
            padding: 15px 11px 5px;
          }
          input[type=text] {
            width: 180px;
          }
          div.button-wrapper {
            border-top: 1px solid $color_dawn_pink_approx;
            text-align: center;
            padding: 3px 0 18px;
          }
        }
        &.reserve-button {
          display: block;
          height: 32px;
          width: 232px;
          background: url(../Image/Desktop/Header/ReserveBottom.png) no-repeat 0 0;
          text-align: center;
          color: $color_donkey_brown_approx;
          cursor: pointer;
          div {
            height: 25px;
            span {
              display: none;
            }
          }
        }
      }
      &.open div.reserve-button div span {
        font-weight: bold;
        display: inline;
      }
    }
  }
}
footer.footer {
  height: 200px;
  nav {
    position: absolute;
    top: 29px;
    z-index: 2;
    right: 240px;
    ul li a {
      color: $color_pot_pourri_approx;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
      opacity: .9;
      text-decoration: underline;
      margin-bottom: 4px;
      display: block;
      padding-left: 15px;
      background: url(../Image/Desktop/Icon/ArrowFooter.png) 0 5px no-repeat;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .meta {
    right: 0;
  }
  ul.teaser {
    position: relative;
    z-index: 2;
    margin-top: 25px;
    li {
      float: left;
      margin-right: 18px;
    }
  }
}
body.fr-language header.header nav.main-navigation ul li a {
  padding-left: 9px;
  padding-right: 9px;
}
